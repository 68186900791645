import React, { useContext } from "react"
import PageLayout, { PageContext, PageData } from "../PageLayout"
import BlockContent from "@sanity/block-content-to-react"
import { PageMargin, PageTitle, Button } from "../GlobalStyles"
import styled from "styled-components/macro"

const FbButton = styled(Button)`
  display: inline-block;
`

const Content = () => {
  const pageData: PageData = useContext(PageContext)
  const { _rawHometownMomentsText, facebookPageURL } = pageData

  return (
    <>
      <BlockContent blocks={_rawHometownMomentsText} />
      <FbButton as="a" href={facebookPageURL} rel="nofollow" target="_blank">
        View our Facebook Page
      </FbButton>
    </>
  )
}

const HometownMoments = () => {
  return (
    <PageLayout>
      <PageMargin>
        <PageTitle>Hometown Moments</PageTitle>
        <Content />
      </PageMargin>
    </PageLayout>
  )
}

export default HometownMoments
